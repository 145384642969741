import { useAtom, useAtomValue } from "jotai";
import { useLocation } from "react-router-dom";
import { LocaleAtom, LoggedInUser, WidgetAtom } from "../../state/CoreState";

export type Widget = HTMLDivElement & { open: Function; close: Function };

export function Widget({ setWidget, setEmbedWidget }: any) {
  const [locale] = useAtom(LocaleAtom);

  const [widgetType] = useAtom(WidgetAtom);

  const user = useAtomValue(LoggedInUser);

  const location = useLocation();

  const hasAutoPopup = !!location.search;

  return !hasAutoPopup && user && window.squatchToken ? (
    <>
      <div id="squatch-embed" ref={(el: Widget) => setWidget(el)}></div>
      {/* @ts-ignore */}
      <squatch-embed
        ref={(el: Widget) => setEmbedWidget(el)}
        widget={widgetType}
        container={"#squatch-embed"}
        locale={locale}
        // open prop triggers widget to initially load every time
        open
      />
    </>
  ) : (
    <></>
  );
}
