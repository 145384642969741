import { useAtom } from "jotai";
import { LocaleAtom } from "../../state/CoreState";
import { Menu, Dropdown, Space } from "antd";
import { Translate } from "../../assets/icon/Translate";
import { CheckMarkSmall } from "../../assets/icon/CheckMarkSmall";

export default function LocaleSelect() {
  const [locale, setLocale] = useAtom(LocaleAtom);
  const menu = (
    <Menu>
      <Menu.Item>
        {locale === "en" && <CheckMarkSmall />}
        <a onClick={() => setLocale("en")}>English</a>
      </Menu.Item>
      <Menu.Item>
        {locale === "fr" && <CheckMarkSmall />}
        <a onClick={() => setLocale("fr")}>Français</a>
      </Menu.Item>
      <Menu.Item>
        {locale === "tr" && <CheckMarkSmall />}
        <a onClick={() => setLocale("tr")}>Türkçe</a>
      </Menu.Item>
    </Menu>
  );

  const name =
    locale === "en" ? "English" : locale === "fr" ? "Français" : "Türkçe";

  return (
    <Space wrap>
      <Dropdown overlay={menu} placement="topLeft">
        <div className="lang-container">
          <Translate />
          {name}
        </div>
      </Dropdown>
    </Space>
  );
}
