import LandingPageOne from "../../assets/images/LandingPageOne.png";
import LandingPageTwo from "../../assets/images/LandingPageTwo.png";
import LandingPageThree from "../../assets/images/LandingPageThree.png";
import LandingPageFour from "../../assets/images/LandingPageFour.png";
import LandingPageFive from "../../assets/images/LandingPageFive.png";
import { PromoButtons } from "../layout/PromoButtons";
import { LandingPageSection } from "./LandingPageSection";
import { useNavigate } from "react-router";
import "./LandingPage.css";
import { FormattedMessage, useIntl } from "react-intl";
import Footer from "../layout/Footer";
import { useLandingPage } from "./useLandingPage";

export const LandingPage = () => {
  const navigate = useNavigate();
  const { data, states } = useLandingPage();
  const intl = useIntl();

  if (states.loading) {
    return <></>;
  }

  return (
    <>
      <div className="landing-main-container">
        <div className="landing-referred-user-text-container">
          {data.referralCode ? (
            <>
              <h1 className="referred-user-title">
                <FormattedMessage
                  id="kug325kjv32326"
                  defaultMessage="{name} invited you to try Klip"
                  description={"Landing page H1 title"}
                  values={{ name: data.referrerName }}
                />
              </h1>
              <p className="referred-user-subtitle">
                <FormattedMessage
                  id="iuvg2p0326bvib36326"
                  defaultMessage="Get a $50 credit towards any annual subcription when you apply this
            referral code:"
                  description={"Landing page Subtitle"}
                />
              </p>
              <p className="landing-user-code">{data.referralCode}</p>
              <PromoButtons login={false} {...{ navigate }} />
            </>
          ) : (
            <>
              <h1 className="referred-user-title">
                <FormattedMessage
                  id="asdasd23421346"
                  defaultMessage="Try Klip for free"
                  description={"Landing page H1 title"}
                />
              </h1>
              <p className="referred-user-subtitle">
                <FormattedMessage
                  id="asdasdasdgfq313413532g4g4"
                  defaultMessage="Create rich video content without having to leave the browser with our 14 day free trial!"
                  description={"Landing page Subtitle"}
                />
              </p>
              <PromoButtons login={false} {...{ navigate }} />
            </>
          )}
        </div>
        <img className="landing-main-img" src={LandingPageOne} />
      </div>

      <h1 className="landing-primary-title">
        {" "}
        <FormattedMessage
          id="21500pi216bgp316"
          defaultMessage="Level-up your communication"
        />
      </h1>
      <img
        src={LandingPageTwo}
        alt="Customers communicating"
        className="landing-img"
      />

      <h1 className="landing-primary-title">
        <FormattedMessage
          id="21u5po0iu15voj21v521"
          defaultMessage="Never miss an opportunity to share the vision."
        />
      </h1>
      <LandingPageSection
        titleText={intl.formatMessage({
          id: "ugp0ouvijh32vk32v5223",
          defaultMessage: "Record, share, repeat.",
        })}
        subText={intl.formatMessage({
          id: "ug0ug32iv32ikjvk32jb2",
          defaultMessage:
            "Create audio and video recordings with just a few clicks, and easily share with prospects, customers, and colleagues.",
        })}
        image={LandingPageThree}
      />

      <LandingPageSection
        titleText={intl.formatMessage({
          id: "9ug32oiuhvj2v32kv32ihv32ghfdhdrlkjb",
          defaultMessage: "Easy, efficient communication.",
        })}
        subText={intl.formatMessage({
          id: "khvi32v9y32c32jgc32lk",
          defaultMessage:
            "No matter your audience, Klip makes it easy to share the vision. Promote new features, improve outreach, and enhance collaboration.",
        })}
        image={LandingPageFour}
      />

      <LandingPageSection
        titleText={intl.formatMessage({
          id: "khviuh32o6jtbiuyvs3264",
          defaultMessage: "Update the way you communicate.",
        })}
        subText={intl.formatMessage({
          id: "adsgjhb9ou46bjb4",
          defaultMessage:
            "Simple integrations with the tools you already use and intuitive transcribing capabilities means faster and more effective communication.",
        })}
        image={LandingPageFive}
      />
      <div className="landing-footer-content">
        <h1 className="landing-secondary-title">
          <FormattedMessage
            id="326iu32o2o3j6vo32b325"
            defaultMessage="A modern solution for any team."
          />
        </h1>
        <PromoButtons login={false} {...{ navigate }} />
      </div>
      <Footer
        termsLink="https://example.com/"
        faqLink="https://example.com/"
        supportLink="https://example.com/"
      />
    </>
  );
};
