import { Button } from "antd";
import { Container } from "../layout/Container";
import "./Dashboard.css";
import { useDashboard } from "./useDashboard";
import { VideoCard } from "../video-card/VideoCard";
import { FormattedMessage, useIntl } from "react-intl";
import { Avatar } from "../layout/Avatar/Avatar";

export const Dashboard = () => {
  const { callbacks, data } = useDashboard();
  const userVideosInLocalStorage = callbacks.getVideosFromStorage();
  const intl = useIntl();

  const Profile = () => (
    <Container alignItems="flex-start">
      <div className="avatar-container">
        <Avatar dropdown={false} />
      </div>
      <div className="dashboard-profile-text-container">
        <p className="dashboard-user-name-text">
          {data.name}
        </p>
        <h1 className="primary-title">
          <FormattedMessage
            id="nbvioutuyy45298djut8"
            defaultMessage="Personal Library"
          />
        </h1>
      </div>
    </Container>
  );

  return !userVideosInLocalStorage.length ? (
    <div className="dashboard-container">
      <Profile />
      <div className="dashboard-text-container">
        <h1 className="dashboard-title-text">
          <FormattedMessage
            id="456uyojnb3ght2jh"
            defaultMessage="No videos recorded yet"
          />
        </h1>
        <p className="empty-text">
          <FormattedMessage
            id="234vfrymnvbuuaswqfop"
            defaultMessage="Every time you record a new video, you’ll find it here. Only you can
          manage videos in this library."
          />
        </p>
        <Button type="primary" onClick={callbacks.handleClick}>
          <FormattedMessage
            id="100uuikllkhjytvbgtrwe2n"
            defaultMessage="Record your first video"
          />
        </Button>
      </div>
    </div>
  ) : (
    <div className="dashboard-container">
      <Profile />
      <div className="dashboard-contents">
        <div className="video-heading">
          <h1 className="dashboard-title-text">
            <FormattedMessage
              id="cxcswwer3412hy76zam"
              defaultMessage="Videos"
            />
          </h1>
          <Button type="primary" onClick={callbacks.handleClick}>
            <FormattedMessage
              id="vyttrekjhnbbwqaswerQiuthv22"
              defaultMessage="New Video"
            />
          </Button>
        </div>
        <div className="video-cards-container">
          {userVideosInLocalStorage.map(
            (
              video: {
                imageIndex: number;
                descriptionIndex: number;
                uploadTimeIndex: number;
              },
              index: number
            ) => {
              const { imageIndex, descriptionIndex, uploadTimeIndex } = video;

              return (
                <VideoCard
                  description={data.videoDescriptions[descriptionIndex]}
                  uploadedText={data.videoUploadTimes[uploadTimeIndex]}
                  image={data.videoCardImages[imageIndex]}
                  key={index}
                />
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};
