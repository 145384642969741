import { useAtomValue } from "jotai/utils";
import { LoggedInUser, WidgetIdent } from "../../state/CoreState";
import { useAtom } from "jotai";

export function useVideoCard() {
  const [user] = useAtom(LoggedInUser);
  const widgetIdent = useAtomValue(WidgetIdent);

  function sendEvent() {
    if (!user) return;
    window.squatch?.events()?.track(
      {
        userId: user.email,
        accountId: user.email,
        events: [{ key: "videoShare", fields: { shareCount: 1 } }],
      },
      { jwt: widgetIdent?.jwt || "" }
    );
  }

  return { callbacks: { sendEvent } };
}
