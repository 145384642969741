import "./LandingPage.css";

type LandingPageSectionProps = {
  titleText: string;
  subText: string;
  image: string;
};

export const LandingPageSection = ({
  titleText,
  subText,
  image,
}: LandingPageSectionProps) => {
  return (
    <div className="landing-section-container">
      <div className="landing-text-container">
        <h2 className="landing-title-text">{titleText}</h2>
        <p className="landing-sub-text">{subText}</p>
      </div>
      <img className="landing-section-img" src={image} alt="" />
    </div>
  );
};
