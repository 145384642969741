import { useMyAccount } from "./useMyAccount";
import "./MyAccount.css";

export default function MyAccount(props: any) {
  const { refs } = props;
  useMyAccount(props);
  return <MyAccountView refs={refs}></MyAccountView>;
}

export function MyAccountView(props: any) {
  const { refs } = props;

  return (
    <div className="site-layout-background">
      <div
        className="squatch-embed"
        ref={refs.placeholderRef}
        style={{ position: "relative" }}
      ></div>
    </div>
  );
}
