export const Users = ({ color = "#656274" }: { color?: string }) => {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.682464 9.05216C1.23906 8.25572 2.16765 7.65501 3.51067 7.65501H8.59204C9.93507 7.65501 10.8637 8.25572 11.4202 9.05216C11.9404 9.79638 12.1027 10.6565 12.1027 11.2416V12.5349C12.1027 13.0872 11.655 13.5349 11.1027 13.5349C10.5504 13.5349 10.1027 13.0872 10.1027 12.5349V11.2416C10.1027 10.9648 10.0141 10.5315 9.78091 10.1978C9.58445 9.91672 9.2578 9.65501 8.59204 9.65501H3.51067C2.84491 9.65501 2.51826 9.91672 2.3218 10.1978C2.08858 10.5315 2 10.9648 2 11.2416V12.5349C2 13.0872 1.55228 13.5349 1 13.5349C0.447715 13.5349 0 13.0872 0 12.5349V11.2416C0 10.6565 0.162357 9.79638 0.682464 9.05216Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.2915 4.00511C6.98186 4.00511 7.5415 3.44547 7.5415 2.75511C7.5415 2.06476 6.98186 1.50511 6.2915 1.50511C5.60115 1.50511 5.0415 2.06476 5.0415 2.75511C5.0415 3.44547 5.60115 4.00511 6.2915 4.00511ZM6.2915 5.50511C7.81029 5.50511 9.0415 4.2739 9.0415 2.75511C9.0415 1.23633 7.81029 0.00511217 6.2915 0.00511217C4.77272 0.00511217 3.5415 1.23633 3.5415 2.75511C3.5415 4.2739 4.77272 5.50511 6.2915 5.50511Z"
        fill={color}
      />
      <path
        d="M16.0276 9.01876C15.422 8.22424 14.4394 7.65501 13.0415 7.65501H11.0425C11.3901 7.77025 11.9282 8.10296 12.3901 8.77025C12.5741 9.03597 12.6998 9.33173 12.7915 9.65501H13.0415C13.8343 9.65501 14.221 9.94786 14.437 10.2312C14.6892 10.562 14.78 10.9827 14.78 11.2416V12.5349C14.78 13.0872 15.2277 13.5349 15.78 13.5349C16.3323 13.5349 16.78 13.0872 16.78 12.5349V11.2416C16.78 10.6386 16.5972 9.76595 16.0276 9.01876Z"
        fill={color}
      />
      <path
        d="M8.68046 5.08071C9.09165 5.33821 9.56365 5.48234 10.0485 5.49848C10.5334 5.51461 11.014 5.40218 11.4414 5.17258C11.8688 4.94299 12.2278 4.60441 12.4821 4.1912C12.7364 3.778 12.8768 3.30488 12.8891 2.81987C12.9014 2.33486 12.7852 1.85522 12.5523 1.42963C12.3194 1.00405 11.978 0.647664 11.5628 0.396653C11.1476 0.145643 10.6734 0.00894144 10.1883 0.00042392C9.70319 -0.0080936 9.22448 0.111876 8.80074 0.348156C9.13969 0.744305 9.32419 1.01788 9.52847 1.65326C9.72196 1.54537 9.94055 1.49059 10.1621 1.49448C10.3836 1.49837 10.6001 1.56079 10.7897 1.67541C10.9793 1.79002 11.1351 1.95276 11.2415 2.14709C11.3479 2.34142 11.4009 2.56044 11.3953 2.78191C11.3897 3.00337 11.3256 3.21941 11.2095 3.40809C11.0934 3.59677 10.9294 3.75137 10.7342 3.85621C10.5391 3.96105 10.3197 4.01239 10.0982 4.00502C9.87683 3.99765 9.6613 3.93184 9.47354 3.81426C9.27308 4.40393 9.10495 4.67002 8.68046 5.08071Z"
        fill={color}
      />
    </svg>
  );
};
