type ContainerProps = {
  padding?: string;
  margin?: string;
  height?: string;
  minHeight?: string;
  width?: string;
  alignItems?: string;
  justifyContent?: string;
  flexDirection?: any;
  children?: React.ReactNode;
  backgroundColor?: string;
  borderColor?: string;
  boxShadow?: string;
  border?: string;
  borderRadius?: string;
};

export const Container = ({
  padding,
  margin,
  height,
  minHeight,
  width,
  alignItems,
  justifyContent,
  flexDirection,
  children,
  backgroundColor,
  border,
  borderRadius,
  boxShadow,
}: ContainerProps) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: justifyContent ? justifyContent : "",
        alignItems: alignItems ? alignItems : "",
        flexDirection: flexDirection ? flexDirection : "row",
        padding: padding ? padding : "",
        margin: margin ? margin : "",
        height: height ? height : "",
        width: width ? width : "",
        backgroundColor: backgroundColor ? backgroundColor : "#fff",
        border: border ? border : "",
        borderRadius: borderRadius ? borderRadius : "",
        boxShadow: boxShadow ? boxShadow : "",
        minHeight: minHeight ? minHeight : "",
      }}
    >
      {children}
    </div>
  );
};
