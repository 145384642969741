import { useAtom } from "jotai";
import { useNavigate } from "react-router";
import { LoggedInUser } from "../../state/CoreState";

export function useBackButton() {
  const navigate = useNavigate();
  const [user] = useAtom(LoggedInUser);

  function redirectUser() {
    if (!user) {
      navigate("/");
      return;
    }
    navigate(-1);
  }

  return { callbacks: { redirectUser } };
}
