import { ReactElement } from "react";
import { Container } from "../../layout/Container";
import "../Settings.css";

type SettingsStatusCardProps = {
  planText: string;
  planPrice: string;
  priceSubText: string;
  seatsText: string;
  seatsSubText: string;
  badgePrefix: string;
  children: ReactElement;
};

export const SettingsStatusCard = ({
  planText,
  planPrice,
  priceSubText,
  seatsText,
  seatsSubText,
  badgePrefix,
  children,
}: SettingsStatusCardProps) => {
  return (
    <Container
      border="1px solid #e0e0e0"
      boxShadow="0px 0px 15px 3px rgba(0, 0, 0, 0.05)"
      width="100%"
      margin="40px 0 60px 0"
      flexDirection="column"
      justifyContent="space-between"
      borderRadius="8px"
    >
      <div className="status-card-top">
        <p className="settings-card-title">{planText}</p>
        <div className="settings-status-container">
          <p className="settings-status-info-text">{badgePrefix}</p>
          {/* Status card takes in the badges as children */}
          <div className="badge-container">{children}</div>
        </div>
      </div>
      <Container
        padding="0px 30px 40px"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        borderRadius="8px"
      >
        <div className="settings-status-price-container">
          <h1 className="primary-title">{planPrice}</h1>
          <p className="settings-status-info-text">{priceSubText}</p>
        </div>
        <div className="settings-status-seats-container">
          <h1 className="primary-title">{seatsText}</h1>
          <p className="settings-status-info-text">{seatsSubText}</p>
        </div>
      </Container>
    </Container>
  );
};
