import { atom, useAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import { useEffect, useState } from "react";
import { LocaleAtom, LoggedInUser, WidgetIdent } from "../../state/CoreState";
import { myCustomStorageAtom } from "../../state/StorageAtom";
import { useAuthRequired } from "../../state/useAuthRequired";
import { DateTime } from "luxon";

export const businessPlan = myCustomStorageAtom("businessPlan", "false");

export const getEndDate = (daysLeft: number, locale: string) => {
  const endDate = DateTime.now()
    .plus({ days: daysLeft })
    .setLocale(locale)
    .toLocaleString({ month: "short", day: "numeric", year: "numeric" });

  return endDate;
};

const referralAtom = atom("");

export const useSettings = () => {
  useAuthRequired();
  const [modalState, setModalState] = useState("");
  const [referralCode, setReferralCode] = useAtom(referralAtom);
  const [user] = useAtom(LoggedInUser);
  const [purchasedBusinessPlan, setPurchasedBusinessPlan] =
    useAtom(businessPlan);
  const widgetIdent = useAtomValue(WidgetIdent);
  const [locale] = useAtom(LocaleAtom);

  useEffect(() => {
    if (!user) return;
    const businessPlan =
      localStorage.getItem(`${user?.email}:businessPlan`) || "false";
    setPurchasedBusinessPlan(JSON.parse(businessPlan));
  }, [user]);

  useEffect(() => {
    async function getReferredByCode() {
      try {
        await fetch(
          `${process?.env.REACT_APP_DOMAIN}/api/v1/${process?.env.REACT_APP_TENANT_ALIAS}/open/account/${user?.email}/user/${user?.email}`,
          {
            headers: {
              Authorization: "Bearer " + widgetIdent?.jwt,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setReferralCode(data?.referredByCodes[0]);
          });
      } catch (e) {
        console.log(e);
      }
    }
    getReferredByCode();
  });

  //Send Business plan event to SSQT
  async function sendEvent() {
    if (!user) return;
    window.squatch?.events()?.track(
      {
        userId: user.email,
        accountId: user.email,
        events: [
          {
            key: "purchasedPlan",
            fields: { revenue: 550, seats: 5, planType: "business" },
          },
        ],
      },
      { jwt: widgetIdent?.jwt || "" }
    );
    if (referralCode) {
      try {
        var graphqlBody = JSON.stringify({
          query:
            'mutation ($userId: String!, $accountId: String!){\r\n  redeemUserCredit(userId:$userId, \r\n    accountId:$accountId, redeemCreditInput:{\r\n      amount:5000,\r\n      unit: "USD"\r\n    }){\r\n    unit\r\n  }\r\n}',
          variables: { userId: user.email, accountId: user.email },
        });

        await fetch(
          `${process?.env.REACT_APP_DOMAIN}/api/v1/${process?.env.REACT_APP_TENANT_ALIAS}/graphql`,
          {
            method: "POST",
            headers: {
              Authorization:
                "Basic " + btoa(`:${process?.env.REACT_APP_API_KEY}`),
              "Content-Type": "application/json",
            },
            body: graphqlBody,
          }
        )
          .then((response) => response.json())
          .then((data) => console.log(data));
      } catch (e) {
        console.log("ERROR", e);
      }
    }
  }

  const onOk = () => {
    if (!modalState) {
      setModalState("confirmation");
    }

    if (modalState === "confirmation") {
      setModalState("success");
    }

    if (modalState === "success") {
      setModalState("");
    }
  };

  const onCancel = () => {
    if (modalState === "success") {
      setPurchasedBusinessPlan("true");
    }
    setModalState("");
  };

  return {
    callbacks: {
      onOk,
      setModalState,
      sendEvent,
      setPurchasedBusinessPlan,
      onCancel,
    },
    states: {
      modalState,
      purchasedBusinessPlan: purchasedBusinessPlan === "true" ? true : false,
      referral: referralCode ? true : false,
    },
    data: { locale },
  };
};
