import { Button, Modal } from "antd";
import { ReactEventHandler } from "react";
import { FormattedMessage } from "react-intl";
import { DownArrow } from "../../../assets/icon/DownArrow";
import { Container } from "../../layout/Container";
import "../Settings.css";

type SettingsModalProps = {
  onOk: ReactEventHandler;
  onCancel: ReactEventHandler;
  onInviteUser: ReactEventHandler;
  visible: boolean;
  modalState: string;
  referral: boolean;
};

export const SettingsModal = ({
  onOk,
  onCancel,
  onInviteUser,
  visible,
  modalState,
  referral,
}: SettingsModalProps) => {
  const ConfirmationModalView = () => {
    return (
      <div className="confirmation-modal-container">
        <h1 style={{ alignSelf: "flex-start" }} className="primary-title">
          <FormattedMessage
            id="iplk3209jhrtycvbwqer"
            defaultMessage="Purchase Plan"
          />
        </h1>
        <div className="modal-cards-container">
          <div className="modal-card-container">
            <h1 className="settings-card-title">
              <FormattedMessage
                id="vbrw341swqzmklo4uytu"
                defaultMessage="Business Plan (billed annually)"
              />
            </h1>

            <div className="plan-card-price-container">
              <h1 className="primary-title">
                <FormattedMessage
                  id="asdfad367kthd4656k"
                  defaultMessage="$50"
                />
              </h1>
              <p className="plan-card-info-text">
                <FormattedMessage
                  id="jtyu56743fhhje4v"
                  defaultMessage="per month"
                />
              </p>
            </div>

            <Container
              justifyContent="center"
              alignItems="center"
              backgroundColor="rgba(0,0,0,0)"
            >
              <p className="modal-show-details-text">
                <FormattedMessage
                  id="gtyu76mnklo1a23sdcv"
                  defaultMessage="Show details"
                />
              </p>
              <DownArrow />
            </Container>
          </div>
          <div className="modal-card-container">
            <h1 className="settings-card-title">
              <FormattedMessage
                id="eertyuijhgfsd1235nm"
                defaultMessage="Bill Summary"
              />
            </h1>

            <div className="modal-cost-list">
              <div className="modal-cost-item">
                <p className="modal-cost-item-text">
                  <FormattedMessage
                    id="poiuyt76nb4v8mloiw"
                    defaultMessage="Business Plan"
                  />
                </p>
                <p className="modal-cost-item-price">
                  <FormattedMessage
                    id="lkuyt685vb3kljgnb"
                    defaultMessage="$600.00"
                  />
                </p>
              </div>
              {referral && (
                <div className="modal-cost-item">
                  <p className="modal-cost-item-text">
                    <FormattedMessage
                      id="qwmrl8l90nb43jghtyuejkv3"
                      defaultMessage="Referral credit"
                    />
                  </p>
                  <p className="modal-cost-item-price">
                    <FormattedMessage
                      id="bvcxnyturhg76598jlout"
                      defaultMessage="-$50.00"
                    />
                  </p>
                </div>
              )}

              <div className="modal-cost-item">
                <p className="modal-cost-item-text">
                  <FormattedMessage
                    id="qwertttyyuujjhgffdcx34556dfs"
                    defaultMessage="Total"
                  />
                </p>
                {referral ? (
                  <p className="modal-cost-item-total">
                    <FormattedMessage
                      id="yu345xcdf17juio23qw25s"
                      defaultMessage="$550.00"
                    />
                  </p>
                ) : (
                  <p className="modal-cost-item-total">
                    <FormattedMessage
                    id="lkuyt685vb3kljgnb"
                    defaultMessage="$600.00"
                  />
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <Button
          id="purchase-plan-btn"
          type="primary"
          onClick={onOk}
          style={{ paddingBottom: "20px", alignSelf: "flex-end" }}
        >
          <FormattedMessage
            id="wercvb67yu4fvbnm1aw34"
            defaultMessage="Purchase plan"
          />
        </Button>
      </div>
    );
  };

  const SuccessModalView = () => {
    return (
      <div className="success-modal-container">
        <h1 style={{ alignSelf: "flex-start" }} className="primary-title">
          <FormattedMessage
            id="3rtyyuuuinbbb56ggfg"
            defaultMessage="Thank you!"
          />
        </h1>
        <p className="success-modal-message">
          <FormattedMessage
            id="werpoiunb665bb8k3dd"
            defaultMessage="You have successfully purchased the Business Plan with 5 users. You
          will recieve a confirmation email with order details."
          />
          <br />
          <br />
          <FormattedMessage
            id="vvddrrt4545dcddjuii"
            defaultMessage="Get started by inviting users to your team!"
          />
        </p>
        <Button id="success-btn" type="primary" onClick={onInviteUser}>
          <FormattedMessage
            id="12dd555gvbbvtyuioplk"
            defaultMessage="Invite users"
          />
        </Button>
      </div>
    );
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width={"80vw"}
      footer={false}
    >
      {modalState === "confirmation" ? (
        <ConfirmationModalView />
      ) : (
        <SuccessModalView />
      )}
    </Modal>
  );
};
