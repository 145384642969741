type BadgeProps = {
  textColor: string;
  backgroundColor: string;
  children: any;
  margin?: string;
};

export const Badge = ({
  textColor,
  backgroundColor,
  children,
  margin,
}: BadgeProps) => {
  return (
    <div
      style={{
        textAlign: "center",
        color: textColor,
        backgroundColor,
        padding: "7px 10px 7px 10px",
        borderRadius: "8px",
        margin: margin ? margin : "",
      }}
    >
      {children}
    </div>
  );
};
