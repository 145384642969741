import React from "react";

export const Logo = ({ onClick }: { onClick?: () => unknown }) => {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 350 109"
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style>
        {
          ".prefix__st3{fill:#4225c4}.prefix__st6{fill:#100931} #prefix__Layer_1:hover {cursor: pointer}"
        }
      </style>
      <path
        className="prefix__st3"
        d="M173.43 37.66a.951.951 0 01-.71 1.16c-.24.06-.5.02-.72-.11l-6.21-3.8a.966.966 0 01-.32-1.32l3.77-6.15c.14-.22.35-.37.6-.43l.05-.01a.96.96 0 011.16.71l2.38 9.95z"
      />
      <path
        className="prefix__st3"
        d="M198.82 26.77c-4.46-18.61-23.17-30.08-41.79-25.61-18.35 4.4-29.75 22.64-25.79 41l.18.79c2.44 10.17 9.13 18.21 17.74 22.67 7.14 3.72 15.6 4.97 24.05 2.94 18.61-4.46 30.08-23.17 25.61-41.79zm-13.34 24.82l-7.1 1.73a.98.98 0 01-.73-.11l-18.64-11.39 3.55 14.82a.953.953 0 01-.71 1.15l-7.29 1.75a.951.951 0 01-1.16-.71l-9.78-40.77a.96.96 0 01.71-1.16l7.29-1.75c.52-.12 1.03.2 1.16.71l3.53 14.71 11.3-18.44c.13-.22.34-.37.59-.43l7.28-1.8a.96.96 0 011.17.7l9.54 39.83a.963.963 0 01-.71 1.16z"
      />
      <g>
        <path
          className="prefix__st6"
          d="M67.9 23.05h-8.16c-1.34 0-2.42 1.08-2.42 2.42v55.67c0 1.34 1.08 2.42 2.42 2.42h8.16c1.34 0 2.42-1.08 2.42-2.42V25.47c0-1.34-1.08-2.42-2.42-2.42zM92.45 25.18c-1.41-1.39-3.08-2.09-5.02-2.09-2 0-3.71.7-5.13 2.09-1.41 1.39-2.12 3.07-2.12 5.04 0 2 .71 3.69 2.12 5.08 1.42 1.4 3.13 2.09 5.13 2.09 1.94 0 3.61-.7 5.02-2.11 1.4-1.4 2.11-3.09 2.11-5.06s-.71-3.65-2.11-5.04zm-1.32 17.25h-7.56a2.68 2.68 0 00-2.68 2.68v35.77c0 1.48 1.2 2.68 2.68 2.68h7.56c1.48 0 2.68-1.2 2.68-2.68V45.11c0-1.48-1.2-2.68-2.68-2.68zM147.66 54.49c-1.05-2.73-2.49-5-4.31-6.8-1.83-1.8-3.94-3.18-6.35-4.15-1.82-.74-3.75-1.2-5.76-1.38-.64-.05-1.28-.08-1.94-.08-2.76 0-5.24.48-7.44 1.46-1.09.47-2.03 1.05-2.85 1.72-.27.22-.58.32-.89.32-.71 0-1.4-.55-1.45-1.36-.05-.76-.69-1.35-1.45-1.35h-9.37c-.8 0-1.44.64-1.44 1.44v58.01c0 .8.64 1.45 1.44 1.45h9.95c.8 0 1.45-.65 1.45-1.45V83.38c0-1.18 1.33-1.87 2.28-1.18.79.56 1.68 1.05 2.68 1.46 2.15.88 4.52 1.32 7.09 1.32 2.73 0 5.3-.49 7.7-1.46 2.41-.97 4.52-2.35 6.35-4.14 1.82-1.78 3.26-4.04 4.31-6.77.83-2.15 1.33-4.48 1.5-6.99.06-.68.08-1.37.08-2.07 0-3.31-.53-6.33-1.58-9.06zm-14.14 16.98c-1.89 1.94-4.3 2.91-7.21 2.91-2.92 0-5.33-.98-7.25-2.95-1.92-1.97-2.88-4.6-2.88-7.88 0-3.31.96-5.95 2.88-7.92 1.92-1.97 4.33-2.95 7.25-2.95 2.89 0 5.28.97 7.19 2.93 1.9 1.96 2.85 4.6 2.85 7.94 0 3.33-.94 5.97-2.83 7.92zM12.11 22.96H1.75c-.73 0-1.32.59-1.32 1.32v57.86c0 .73.59 1.33 1.32 1.33h10.36c.73 0 1.32-.6 1.32-1.33V24.28c0-.73-.59-1.32-1.32-1.32z"
        />
        <path
          className="prefix__st6"
          d="M45.82 23.58l-10.36.08c-.35 0-.68.14-.92.38L13.43 45.16l-7.05 7.05c-.52.52-.52 1.36 0 1.88l7.05 7.03L34.78 82.4c.25.25.59.39.94.39l10.09-.04c.73 0 1.32-.59 1.32-1.32l.02-56.52c0-.74-.6-1.33-1.33-1.33zM34.13 60.19c0 .73-.59 1.32-1.33 1.32-.35 0-.68-.14-.93-.38l-7.11-7.09c-.52-.52-.52-1.36 0-1.88l7.04-7.04c.25-.25.58-.39.93-.39h.07c.74 0 1.33.59 1.33 1.33v14.13z"
        />
      </g>
    </svg>
  );
};
