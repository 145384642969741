import { Alert, Button, Form, Input } from "antd";
import { BackButton } from "../back-button/BackButton";
import { useRegister } from "../register/useRegister";
import "./Forms.css";
import { FormattedMessage, useIntl } from "react-intl";
import Footer from "../layout/Footer";

export default function Register() {
  const { callbacks, states } = useRegister();
  const intl = useIntl();
  return (
    <>
      <BackButton />
      <div className="form-outer-container">
        <div className="register-form-container">
          <h1 className="form-header">
            <FormattedMessage
              id="4325GFSRG435rasg"
              defaultMessage="Sign up for a free trial"
            />
          </h1>
          <p className="form-sub-header">
            <FormattedMessage
              id="jkyfkHRTH4534rff"
              defaultMessage="Start with a 14-day trial of Klip Business. If you don’t like it,
            keep using Klip for free"
            />
          </p>
          <Form
            layout="vertical"
            form={states.form}
            onFinish={callbacks.onSubmit}
            requiredMark={false}
          >
            <div className="name-container">
              <Form.Item
                label={intl.formatMessage({
                  id: "rghjykghjtghe4532sdfg",
                  defaultMessage: "First Name",
                })}
                name="firstName"
                required
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "asjkas87da76sd76a",
                      defaultMessage: "First name required",
                    }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({
                  id: "kyutujytnghhfgvvv4",
                  defaultMessage: "Last Name",
                })}
                name="lastName"
                required
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "asdsdgsfvxcv3423rf32f",
                      defaultMessage: "Last name required",
                    }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <Form.Item
              label={intl.formatMessage({
                id: "thjghdgvx45t345gttyhsert",
                defaultMessage: "Email",
              })}
              name="email"
              required
              rules={[
                {
                  required: true,
                  type: "email",
                  message: intl.formatMessage({
                    id: "asdlk987as8d68sad",
                    defaultMessage: "Email required",
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: "jkhjkrthjisdfgjnk234234",
                defaultMessage: "Password",
              })}
              name="password"
              required
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "fghjfge65fg43j6fg67fg8h9f",
                    defaultMessage: "Password required",
                  }),
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: "tyrtybnffbghfEETR23234",
                defaultMessage: "Confirm Password",
              })}
              name="confirmPassword"
              required
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "sdfksdf76767dsf",
                    defaultMessage: "Password confirmation required",
                  }),
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: "aiusua834aehfgiurgh",
                defaultMessage: "Coupon Code",
              })}
              name="coupon"
            >
              <Input onChange={callbacks.checkCode} />
            </Form.Item>
            {states.validCode && (
              <Alert
                style={{ marginBottom: "24px" }}
                message={intl.formatMessage({
                  id: "adsiuyg789asergh",
                  defaultMessage:
                    "You've been referred! You'll get $50 off your plan.",
                })}
                type="success"
              />
            )}
            <Button type="primary" htmlType="submit" block>
              <FormattedMessage
                id="fgdfdsgdthje564456yjtykuik"
                defaultMessage="Continue"
              />
            </Button>
          </Form>
        </div>
      </div>
      <Footer
        termsLink="https://example.com/"
        faqLink="https://example.com/"
        supportLink="https://example.com/"
      />
    </>
  );
}
