export const Team = ({ color = "#656274" }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 3.31036C11.25 3.12452 11.0544 3.00364 10.8882 3.08676L1.8882 7.58676C1.8035 7.6291 1.75 7.71567 1.75 7.81036V16.6923C1.75 16.8304 1.86193 16.9423 2 16.9423H11.25V3.31036ZM12.75 16.9423H18C18.1381 16.9423 18.25 16.8304 18.25 16.6923V9.19233C18.25 9.05426 18.1381 8.94233 18 8.94233H12.75V16.9423ZM12.75 7.44233V3.31036C12.75 2.00944 11.381 1.16333 10.2174 1.74512L1.21738 6.24512C0.624504 6.54155 0.25 7.14751 0.25 7.81036V16.6923C0.25 17.6588 1.0335 18.4423 2 18.4423H18C18.9665 18.4423 19.75 17.6588 19.75 16.6923V9.19233C19.75 8.22583 18.9665 7.44233 18 7.44233H12.75Z"
        fill={color}
      />
      <path
        d="M5.5 9.69233C5.5 10.2446 5.05228 10.6923 4.5 10.6923C3.94772 10.6923 3.5 10.2446 3.5 9.69233C3.5 9.14004 3.94772 8.69233 4.5 8.69233C5.05228 8.69233 5.5 9.14004 5.5 9.69233Z"
        fill={color}
      />
      <path
        d="M5.5 13.6923C5.5 14.2446 5.05228 14.6923 4.5 14.6923C3.94772 14.6923 3.5 14.2446 3.5 13.6923C3.5 13.14 3.94772 12.6923 4.5 12.6923C5.05228 12.6923 5.5 13.14 5.5 13.6923Z"
        fill={color}
      />
      <path
        d="M9.5 9.69233C9.5 10.2446 9.05229 10.6923 8.5 10.6923C7.94772 10.6923 7.5 10.2446 7.5 9.69233C7.5 9.14004 7.94772 8.69233 8.5 8.69233C9.05229 8.69233 9.5 9.14004 9.5 9.69233Z"
        fill={color}
      />
      <path
        d="M9.5 13.6923C9.5 14.2446 9.05229 14.6923 8.5 14.6923C7.94772 14.6923 7.5 14.2446 7.5 13.6923C7.5 13.14 7.94772 12.6923 8.5 12.6923C9.05229 12.6923 9.5 13.14 9.5 13.6923Z"
        fill={color}
      />
    </svg>
  );
};
