import { useEffect } from "react";
import { atom, useAtom } from "jotai";
import { useIntl } from "react-intl";
import { REFERRAL_PROGRAM } from "../../state/CoreState";

const referralAtom = atom("");
const referrerAtom = atom("");
const loadingAtom = atom(false);

export function useLandingPage() {
  const [referralCode, setReferralCode] = useAtom(referralAtom);
  const [referrerName, setReferrerName] = useAtom(referrerAtom);
  const [loading, setLoading] = useAtom(loadingAtom);
  const intl = useIntl();

  useEffect(() => {
    async function getReferralInfo() {
      setLoading(true);

      const onReady = async () => {
        let returnedCode;
        await window.squatch
          ?.api()
          .squatchReferralCookie()
          .then(function (response: any) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const referralCode = urlParams.get("rsCode") || "";

            // Get programId from URL, otherwise default to referral program
            let programId;
            for (const code in response.codes) {
              if (response.codes[code] === referralCode) {
                programId = code;
              }
            }

            const code = response?.codes[programId || REFERRAL_PROGRAM];
            if (code) {
              returnedCode = code;
              setReferralCode(code);
            }
          });

        if (returnedCode) {
          try {
            await fetch(
              `${process?.env.REACT_APP_DOMAIN}/api/v1/${process?.env.REACT_APP_TENANT_ALIAS}/open/code/${returnedCode}`
            )
              .then((response) => response.json())
              .then((data) => setReferrerName(data?.referrerName));
          } catch (e) {
            console.log(e);
          }
        }
      };
      if (window.squatch) {
        console.log("trying to call squatch.ready", window.squatch);
        window.squatch?.ready(onReady);
      } else {
        console.log("no squatch.ready");
        window.squatchOnReady = onReady;
      }

      setLoading(false);
    }
    getReferralInfo();
  }, []);

  return {
    data: {
      referralCode,
      referrerName:
        referrerName ||
        intl.formatMessage({
          id: "23842983479823794234",
          defaultMessage: "Your friend",
        }),
    },
    states: { loading },
  };
}
