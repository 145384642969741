import { Dropdown, Menu } from "antd";
import { useAtom } from "jotai";
import { FormattedMessage } from "react-intl";
import { REFERRAL_PROGRAM, WidgetAtom } from "../../state/CoreState";

export const Search = () => {
  const [_, setWidget] = useAtom(WidgetAtom);
  const clearDataMenu = (
    <Menu>
      <Menu.Item
        onClick={() => setWidget(`p/${REFERRAL_PROGRAM}/w/referrerWidget`)}
      >
        <FormattedMessage
          id="aertjjatadfgh234234hfgfghd78"
          defaultMessage="Referral Program"
        />
      </Menu.Item>
      <Menu.Item onClick={() => setWidget("p/klip-loyalty/w/pointsWidget")}>
        <FormattedMessage
          id="aertjjatadfgh234234hfgfghd2"
          defaultMessage="Loyalty Program"
        />
      </Menu.Item>
      <Menu.Item onClick={() => setWidget("w/klipMonoWidget")}>
        <FormattedMessage
          id="aertjjatadfgh234234hfgfghd23134324"
          defaultMessage="Referral And Loyalty"
        />
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={clearDataMenu}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6713 9.83566C17.6713 14.1632 14.1632 17.6713 9.83566 17.6713C5.50815 17.6713 2 14.1632 2 9.83566C2 5.50815 5.50815 2 9.83566 2C14.1632 2 17.6713 5.50815 17.6713 9.83566ZM16.2471 17.2947C14.5254 18.776 12.2851 19.6713 9.83566 19.6713C4.40358 19.6713 0 15.2677 0 9.83566C0 4.40358 4.40358 0 9.83566 0C15.2677 0 19.6713 4.40358 19.6713 9.83566C19.6713 12.2134 18.8276 14.3941 17.4232 16.0946L17.6493 15.8685L23.445 21.6642L22.0308 23.0784L16.2471 17.2947Z"
          fill="#555555"
        />
      </svg>
    </Dropdown>
  );
};
