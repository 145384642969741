import { useAtom } from "jotai";
import { UserName } from "../../../state/CoreState";
import { useAuthRequired } from "../../../state/useAuthRequired";
import { VideosAtom } from "../../dashboard/useDashboard";
import { businessPlan } from "../../settings/useSettingsHook";

export const useAvatar = () => {
  useAuthRequired();
  const [_, setVideos] = useAtom(VideosAtom);
  const [userName] = useAtom(UserName);
  const [__, setPurchasedBusinessPlan] = useAtom(businessPlan);

  const handleMenuClick = () => {
    setVideos("");
    setPurchasedBusinessPlan("false");
  };

  return {
    data: {
      //@ts-ignore
      firstInitial: userName?.firstInitial || "R",
    },
    states: {
      purchasedBusinessPlan: () => {},
    },
    callbacks: {
      handleMenuClick,
    },
  };
};
