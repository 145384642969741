export const User = ({ color = "#656274" }: { color?: string }) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.855729 10.5109C1.49345 9.6743 2.5295 9.07141 4.01289 9.07141H9.98795C11.4713 9.07141 12.5074 9.6743 13.1451 10.5109C13.7468 11.3002 13.9403 12.2233 13.9403 12.86V14.2543C13.9403 14.8066 13.4926 15.2543 12.9403 15.2543C12.388 15.2543 11.9403 14.8066 11.9403 14.2543V12.86C11.9403 12.5674 11.8387 12.0962 11.5545 11.7234C11.3065 11.3979 10.8663 11.0714 9.98795 11.0714H4.01289C3.13456 11.0714 2.69437 11.3979 2.4463 11.7234C2.16209 12.0962 2.06055 12.5674 2.06055 12.86V14.2543C2.06055 14.8066 1.61283 15.2543 1.06055 15.2543C0.508262 15.2543 0.0605469 14.8066 0.0605469 14.2543V12.86C0.0605469 12.2233 0.254083 11.3002 0.855729 10.5109Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.96667 5.71458C7.92446 5.71458 8.70091 4.93814 8.70091 3.98034C8.70091 3.02254 7.92446 2.24609 6.96667 2.24609C6.00887 2.24609 5.23242 3.02254 5.23242 3.98034C5.23242 4.93814 6.00887 5.71458 6.96667 5.71458ZM6.96667 7.21458C8.75289 7.21458 10.2009 5.76656 10.2009 3.98034C10.2009 2.19411 8.75289 0.746094 6.96667 0.746094C5.18044 0.746094 3.73242 2.19411 3.73242 3.98034C3.73242 5.76656 5.18044 7.21458 6.96667 7.21458Z"
        fill={color}
      />
    </svg>
  );
};
