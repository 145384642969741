import { Form, Input, Button, Checkbox } from "antd";
import "../forms/Forms.css";
import { useContactSales } from "./useContactSales";
import { BackButton } from "../back-button/BackButton";
import { FormattedMessage, useIntl } from "react-intl";
import Footer from "../layout/Footer";

const { TextArea } = Input;

export const ContactSales = () => {
  const { data } = useContactSales();
  const intl = useIntl();

  return (
    <>
      <BackButton />
      <div className="contact-sales-form-container">
        <div className="contact-sales-text-container">
          <h1 className="primary-title">
            <FormattedMessage
              id="gjrtu454fgdagdfaWQ32"
              defaultMessage="Contact our sales team"
            />
          </h1>
          <p className="paragraph-text">
            <FormattedMessage
              id="rthhrtrtgt4rgf35tgf35t"
              defaultMessage="To learn more and see Klip in action, fill out the form and we'll
            get in touch as soon as possible."
            />
          </p>
          <p className="paragraph-text">
            <FormattedMessage
              id="jiodfgiojdfgoji3422345"
              defaultMessage="We look forward to showing you what Klip can do for your business!"
            />
          </p>
        </div>
        <div className="form-outer-container">
          <form
            action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
            method="POST"
            className="ant-form ant-form-vertical register-form-container"
          >
            <input type="hidden" name="oid" value="00D5f000005x2AN" />
            <input type="hidden" name="retURL" value={data.redirectUrl} />
            <div className="name-container">
              <Form.Item
                colon={false}
                label={intl.formatMessage({
                  id: "rghjykghjtghe4532sdfg",
                  defaultMessage: "First Name",
                })}
              >
                <Input name="first_name" id="first_name" type="text" required />
              </Form.Item>
              <Form.Item
                colon={false}
                label={intl.formatMessage({
                  id: "kyutujytnghhfgvvv4",
                  defaultMessage: "Last Name",
                })}
              >
                <Input name="last_name" id="last_name" type="text" required />
              </Form.Item>
            </div>
            <Form.Item
              colon={false}
              label={intl.formatMessage({
                id: "thjghdgvx45t345gttyhsert",
                defaultMessage: "Email",
              })}
            >
              <Input name="email" id="email" type="text" required />
            </Form.Item>
            <Form.Item
              colon={false}
              label={intl.formatMessage({
                id: "ujystyjt56536sdfghghtdgsdf",
                defaultMessage: "Job Title",
              })}
            >
              <Input name="title" id="title" type="text" required />
            </Form.Item>
            <Form.Item
              colon={false}
              label={intl.formatMessage({
                id: "tyujjtyfg45326adrfhgff",
                defaultMessage: "Company",
              })}
            >
              <Input name="company" id="company" type="text" required />
            </Form.Item>
            <Form.Item
              colon={false}
              label={intl.formatMessage({
                id: "ujkrtyjehnhtyrhb",
                defaultMessage: "How is your team planning to use Klip?",
              })}
            >
              <TextArea name="00N5f00000RGhfA" id="00N5f00000RGhfA" rows={4} />
            </Form.Item>
            <Form.Item>
              <Checkbox>
                <FormattedMessage
                  id="yjjryyjrtjqrtyertw342sdfa"
                  defaultMessage="I agree to Klip's Terms and Privacy Policy."
                />
              </Checkbox>
            </Form.Item>

            <input
              id="00N5f00000MMLqV"
              name="00N5f00000MMLqV"
              hidden
              readOnly
              value={data.referralCode}
              type="text"
            />
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                <FormattedMessage
                  id="tukykj6456tygftyt3456t534"
                  defaultMessage="Contact sales"
                />
              </Button>
            </Form.Item>
          </form>
        </div>
      </div>
      <Footer
        termsLink="https://example.com/"
        faqLink="https://example.com/"
        supportLink="https://example.com/"
      />
    </>
  );
};
