import React from "react";

export const CheckMarkSmall = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: "5px" }}
    >
      <path d="M0 0H12V12H0V0Z" fill="transparent" />
      <path
        d="M4.5002 8.10005L2.4002 6.00005L1.7002 6.70005L4.5002 9.50005L10.5002 3.50005L9.8002 2.80005L4.5002 8.10005Z"
        fill="#222222"
      />
    </svg>
  );
};
