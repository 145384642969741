export const CheckMark = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50016 13.5003L4.00016 10.0003L2.8335 11.167L7.50016 15.8337L17.5002 5.83366L16.3335 4.66699L7.50016 13.5003Z"
        fill="#100932"
      />
    </svg>
  );
};
