import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { atomWithQuery } from "jotai/query";
import * as jwt from "jsonwebtoken";

type EngagementMedium = "POPUP" | "EMBED";

export const REFERRAL_PROGRAM = "klip-referral-program";

export type User =
  | {
      email: string;
      firstName?: string;
      lastName?: string;
    }
  | undefined;

export const LoggedInUser = atomWithStorage<User>("klipUser", undefined);

export const registerAtom = atom(false);

//@ts-ignore
export const UserName = atomWithQuery((get) => {
  const loggedInUser = get(LoggedInUser);
  const widgetIdent = get(WidgetIdent);
  if (!loggedInUser || !widgetIdent?.jwt)
    return { firstName: "", lastName: "", firstInitial: "" };
  return {
    queryKey: "getUserName",
    queryFn: async () => {
      const response = await fetch(
        `${process?.env.REACT_APP_DOMAIN}/api/v1/${process?.env.REACT_APP_TENANT_ALIAS}/open/account/${loggedInUser.email}/user/${loggedInUser.email}`,
        {
          headers: {
            Authorization: "Bearer " + widgetIdent.jwt,
          },
        }
      );
      const { firstName, lastName } = await response.json();
      return {
        firstName,
        lastName,
        firstInitial: firstName?.charAt(0).toLocaleUpperCase(),
      };
    },
  };
});

export const WidgetIdent = atom((get) => {
  const loggedInUser = get(LoggedInUser);
  const locale = get(LocaleAtom);
  const comeFromRegister = get(registerAtom);
  if (!loggedInUser) return;
  const { email, firstName, lastName } = loggedInUser;
  let user = {
    accountId: email,
    id: email,
    email: email,
    firstName,
    lastName,
    locale,
    customFields: {},
  };

  if (comeFromRegister) {
    user = { ...user, customFields: { signupType: "freeTrial" } };
  }

  const token = jwt.sign(
    {
      user,
    },
    //@ts-ignore
    process?.env.REACT_APP_API_KEY,
    { noTimestamp: true }
  );

  // @ts-ignore
  window.squatchToken = token;

  return {
    engagementMedium: "EMBED" as EngagementMedium | undefined,
    appDomain: process?.env.REACT_APP_DOMAIN,
    tenantAlias: process?.env.REACT_APP_TENANT_ALIAS,
    programId: REFERRAL_PROGRAM,
    widgetType: `p/${REFERRAL_PROGRAM}/w/referrerWidget`,
    id: email,
    accountId: email,
    email: email,
    firstName,
    lastName,
    user,
    jwt: token,
  };
});

export const IsLoggedIn = atom((get) => (get(LoggedInUser) ? true : false));

export const LocaleAtom = atomWithStorage("localePreference", "en");

export const WidgetAtom = atomWithStorage("widgetSelected", "w/klipMonoWidget");
