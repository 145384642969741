import { useForm } from "antd/lib/form/Form";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { LoggedInUser } from "../../state/CoreState";

export function useLogin() {
  const [user, setUser] = useAtom(LoggedInUser);
  let navigate = useNavigate();
  const [form] = useForm();

  const onSubmit = (data: { email: string }) => {
    setUser({ email: data.email.toLowerCase() });
    navigate("/app");
  };

  useEffect(() => {
    if (user) {
      // Redirect if already logged in
      navigate("/app");
    }
  }, [user, navigate]);

  return {
    callbacks: { navigate, onSubmit },
    states: { form },
  };
}
