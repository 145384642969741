import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { useAtom, useAtomValue } from "jotai";
import { Suspense, useCallback, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { Outlet, Routes } from "react-router";
import { Route, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { ContactSales } from "./components/contactSales/ContactSales";
import { ContactSalesSuccess } from "./components/contactSales/ContactSalesSuccess";
import { Dashboard } from "./components/dashboard/Dashboard";
import Login from "./components/forms/Login";
import Register from "./components/forms/Register";
import { LandingPage } from "./components/landing-page/LandingPage";
import { Navbar } from "./components/layout/Navbar/Navbar";
import Logout from "./components/logout/Logout";
import { Widget } from "./components/monoWidget/Widget";
import MyAccount from "./components/my-account/MyAccount";
import Navigation from "./components/navigation/Navigation";
import { Settings } from "./components/settings/Settings";
import { MessagesAtom } from "./lang/MessagesAtom";
import { LocaleAtom, WidgetIdent } from "./state/CoreState";

function App() {
  const [locale] = useAtom(LocaleAtom);
  const [messages] = useAtom(MessagesAtom);
  const widgetIdent = useAtomValue(WidgetIdent);

  // EmbedWidget instance
  const [embedWidget, setEmbedWidget] = useState<any>();
  // Div to load widget into
  const [widget, setWidget] = useState<HTMLDivElement>();

  function moveToPlaceholder(placeholder: HTMLDivElement) {
    if (!placeholder || !widget?.style) return;

    function setWidgetPosition() {
      if (!widget || !placeholder) return;

      widget!.style.position = "absolute";
      widget!.style.top = `${placeholder.offsetTop}px`;
      widget!.style.left = `${placeholder.offsetLeft}px`;

      if (widget.firstChild)
        // @ts-ignore
        widget.firstChild.style.width = `${placeholder.offsetWidth}px`;
    }

    // Resize based on placeholder padding / width
    const ro = new ResizeObserver((_) => {
      if (!widget) return;
      setWidgetPosition();
    });
    ro.observe(placeholder);
  }

  const placeholderRef = useCallback(
    async (node) => {
      if (!embedWidget) return;

      if (node !== null) {
        moveToPlaceholder(node);
      }
    },

    [embedWidget]
  );

  const isOpen = window.location.pathname === "/app/klip-rewards";

  const [loaded, setLoaded] = useState(false);

  function widgetLoadedHandler() {
    if (!isOpen) {
      // Remove open attribute on first load if on wrong page
      embedWidget.removeAttribute("open");
    }
    setLoaded(true);
  }

  useEffect(() => {
    if (!embedWidget) return;
    // Widget loaded event - only gets triggered if the widget is opened
    embedWidget.addEventListener("sq:widget-loaded", widgetLoadedHandler);
    return () =>
      embedWidget.removeEventListener("sq:widget-loaded", widgetLoadedHandler);
  }, [embedWidget, isOpen]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <Suspense fallback="">
        <Router>
          <div>
            <Routes>
              {/**
               *
               * Website routes
               *
               */}
              <Route
                path="/"
                element={
                  <>
                    <Navbar hasButtons={true} />
                    <Outlet />
                  </>
                }
              >
                <Route index element={<LandingPage />} />
              </Route>
              <Route
                path="/"
                element={
                  <>
                    <Navbar hasButtons={false} />
                    <Outlet />
                  </>
                }
              >
                <Route path="contact-sales" element={<ContactSales />} />
                <Route
                  path="contact-sales-success"
                  element={<ContactSalesSuccess />}
                />
                <Route path="login" element={<Login />} />
                <Route path="logout" element={<Logout />} />
                <Route path="register" element={<Register />} />
              </Route>
              {/**
               *
               * App routes
               *
               */}
              <Route
                path="app"
                element={
                  <Navigation>
                    <Outlet />
                  </Navigation>
                }
              >
                <Route index element={<Dashboard />} />
                <Route path="plans" element={<Settings />} />

                <Route
                  path="klip-rewards"
                  element={
                    <MyAccount
                      refs={{
                        placeholderRef,
                      }}
                      embedWidget={embedWidget}
                      loaded={loaded}
                    ></MyAccount>
                  }
                />
              </Route>
            </Routes>
          </div>
          <Widget setWidget={setWidget} setEmbedWidget={setEmbedWidget} />
        </Router>
      </Suspense>
    </IntlProvider>
  );
}

export default App;
