import { atom } from "jotai";
import { LoggedInUser } from "./CoreState";

export const myCustomStorageAtom = (key: string, fallbackValue: string) => {
  const getInitialValue = () => {
    const user = localStorage.getItem("klipUser");
    
    if (user === "undefined" || !user) {
      return fallbackValue;
    }
    const userObject = JSON.parse(user) as { email: string };

    return JSON.parse(
      localStorage.getItem(`${userObject?.email}:${key}`) || fallbackValue
    );
  };

  const baseAtom = atom(getInitialValue());

  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, newVal: string) => {
      const user = get(LoggedInUser);
      localStorage.setItem(`${user?.email}:${key}`, JSON.stringify(newVal));
      set(baseAtom, newVal);
    }
  );
  return derivedAtom;
};
